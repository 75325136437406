export const crud = [
  {
    path: "*",
    name: "error404",
    component: () =>
      import(/* webpackChunkName: "InventoryChunk" */ "@/Views/Error404.vue"),
    meta: {
      title: "Page Not Found",
    },
  },
  {
    path: "/",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "InventoryChunk" */ "@/Views/Login.vue"),
    meta: {
      title: "Login",
    },
  },
  {
    path: "/dashboard",
    name: "DashboardPage",
    component: () => import("@/Views/Dashboard/Index.vue"),
    meta: {
      title: "Dashboard",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/stock/categories",
    name: "Categories",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Stock/Categories.vue"
      ),
    meta: {
      title: "Item Types",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/stock/units",
    name: "Units",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Stock/Units.vue"
      ),
    meta: {
      title: "Items Units",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/payments/modes",
    name: "PaymentModes",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Stock/PaymentModes.vue"
      ),
    meta: {
      title: "Payments Mode",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/lead/suppliers",
    name: "Suppliers",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Lead/Suppliers.vue"
      ),
    meta: {
      title: "Suppliers",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/lead/clients",
    name: "Clients",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Lead/Clients.vue"
      ),
    meta: {
      title: "Clients",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/stock/products",
    name: "Products",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Stock/Products.vue"
      ),
    meta: {
      title: "Product",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/stock/initialize",
    name: "ProductsInitializor",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Stock/Initializer.vue"
      ),
    meta: {
      title: "Initialize Products",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/stock/products/create/:reference?",
    name: "ProductCreator",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Stock/ProductCreator.vue"
      ),
    meta: {
      title: "Create Product",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/stock/products/receive/:reference?",
    name: "ProductReceive",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Stock/Receive.vue"
      ),
    meta: {
      title: "Receive Products",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/stock/products/deliver/:reference?",
    name: "StockDelivery",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Stock/Deliver.vue"
      ),
    meta: {
      title: "Stock Transfer",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/stock/products/spoiled/create",
    name: "Spoiled",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Stock/Spoiled.vue"
      ),
    meta: {
      title: "Spoiled Items",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/stock/adjustment/:action/:reference?",
    name: "NewInventoryAdjustment",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Stock/Adjustment.vue"
      ),
    meta: {
      title: "Create Inventory Adjustment",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/stock/adjustments",
    name: "InventoryAdjustments",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Reports/Adjustments.vue"
      ),
    meta: {
      title: "Inventory Adjustments",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/stock/purchase-orders/:reference?",
    name: "PurchaseOrders",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Stock/PurchaseOrders.vue"
      ),
    meta: {
      title: "Purchase Orders",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/branches",
    name: "Branches",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Branches/Index.vue"
      ),
    meta: {
      title: "Branches Listing",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/branches/sales-item",
    name: "BranchItems",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Branches/SalesItems.vue"
      ),
    meta: {
      title: "Sales Items",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/branches/sales-item/create/:reference?",
    name: "BranchItemsCreator",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Branches/ItemCreator.vue"
      ),
    meta: {
      title: "Create Sales Items",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/branches/stock/auto-requisitions",
    name: "AutoRequisitions",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Branches/AutoRequisitions.vue"
      ),
    meta: {
      title: "Auto Requisitions",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/branches/sales/invoices/:reference?",
    name: "BranchSalesInvoices",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Branches/SalesInvoicesCreator.vue"
      ),
    meta: {
      title: "Create Sales Invoice",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/finance/accounts-summary",
    name: "BranchPaymentAccounts",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Settings/Accounts.vue"
      ),
    meta: {
      title: "Accounts  Summary",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/production/sources",
    name: "ProductionSource",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Production/ProductionSource.vue"
      ),
    meta: {
      title: "Production Sources",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/production/stock/transfer/:reference?",
    name: "ProductionTransfer",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Stock/Deliver.vue"
      ),
    meta: {
      title: "Production Stock Transfer",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/production/items",
    name: "ProductionItems",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Production/Index.vue"
      ),
    meta: {
      title: "Production Items",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/production/create/:reference?",
    name: "ProductionItemCreator",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Production/ItemCreator.vue"
      ),
    meta: {
      title: "Create Production Item",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/production/request-ingredentients",
    name: "ProductionMake",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Production/Create.vue"
      ),
    meta: {
      title: "Request Ingredients",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/production/status",
    name: "ProductionStatus",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Production/Status.vue"
      ),
    meta: {
      title: "Production Status",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/production/sales/:reference?",
    name: "ProductionSales",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Production/Sales.vue"
      ),
    meta: {
      title: "Sales - Production",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/production/raw-materials/requisitions",
    name: "ProductionRequests",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Branches/Requester.vue"
      ),
    meta: {
      title: "Production Requisitions",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/production/invoices",
    name: "ProductionInvoices",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Reports/SalesReport.vue"
      ),
    meta: {
      title: "Production Invoices",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/production/spoiled/create",
    name: "ProductionSpoiled",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Stock/Spoiled.vue"
      ),
    meta: {
      title: "Production Spoiled Items",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/production/raw-materials/receive/:reference?",
    name: "ProductionReceive",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Stock/Receive.vue"
      ),
    meta: {
      title: "Receive Production items",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/production/adjustment/:action/:reference?",
    name: "NewProductionAdjustment",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Stock/Adjustment.vue"
      ),
    meta: {
      title: "Create Adjustment",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/branches/items/adjustment/:action/:reference?",
    name: "NewBranchAdjustment",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Stock/Adjustment.vue"
      ),
    meta: {
      title: "Create Adjustment",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/production/adjustments",
    name: "ProductionAdjustments",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Reports/Adjustments.vue"
      ),
    meta: {
      title: "Stock Adjustments",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/branches/items/adjustments",
    name: "BranchAdjustments",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Reports/Adjustments.vue"
      ),
    meta: {
      title: "Stock Adjustments",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/branches/stock/transfer/:reference?",
    name: "BranchTransfer",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Stock/Deliver.vue"
      ),
    meta: {
      title: "Production Stock Transfer",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/production/requisitions",
    name: "ProductionRequisitions",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Branches/Requests.vue"
      ),
    meta: {
      title: "Requisitions",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/production/raw-materials-requests",
    name: "RawMeterialsRequests",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Branches/Requests.vue"
      ),
    meta: {
      title: "Raw Meterials Requests",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/lead/employees",
    name: "Users",
    component: () =>
      import(/* webpackChunkName: "InventoryChunk" */ "@/Views/Lead/Users.vue"),
    meta: {
      title: "Employees",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/settings",
    name: "Settings",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Settings/General.vue"
      ),
    meta: {
      title: "General Settings",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/settings/users/privileges",
    name: "Permissions",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Settings/Permissions.vue"
      ),
    meta: {
      title: "Permissions Matrix",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/settings/users/roles",
    name: "UserRoles",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Settings/UserRoles.vue"
      ),
    meta: {
      title: "User Roles",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/settings/users/roles/:action/:id?",
    name: "RolesCreator",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Settings/RolesCreator.vue"
      ),
    meta: {
      title: "Create Roles",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/settings/finance/accounts",
    name: "PaymentAccounts",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Settings/Accounts.vue"
      ),
    meta: {
      title: "Payments Account",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/settings/finance/account-types",
    name: "PaymentAccountsTypes",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Settings/AccountTypes.vue"
      ),
    meta: {
      title: "Set Account Types",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/requisition",
    name: "Requests",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Branches/Requests.vue"
      ),
    meta: {
      title: "Requisition",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/requisition/create",
    name: "CreateRequest",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Branches/Requester.vue"
      ),
    meta: {
      title: "Requests Items",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/requisition/print/:reference",
    name: "RequisitionPrint",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Branches/RequisitionPrint.vue"
      ),
    meta: {
      title: "Print Requisition",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/purcharses/create/:reference?",
    name: "PurchaseCreator",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Branches/PurchaseCreator.vue"
      ),
    meta: {
      title: "Purchases Management",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/stock-count",
    name: "StockCount",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Branches/StockCount.vue"
      ),
    meta: {
      title: "Physical Stock Count",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/finance/suppliers/bulk-payment",
    name: "BulkPayment",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Finance/BulkPayment.vue"
      ),
    meta: {
      title: "Bulk Suppliers Payment",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/finance/customers/bulk-payment",
    name: "InvoicesBulkPayment",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Finance/InvoicesBulkPayment.vue"
      ),
    meta: {
      title: "Bulk Invoices Payment",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/finance/suppliers/payments",
    name: "SupplierPayments",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Finance/SupplierPayments.vue"
      ),
    meta: {
      title: "Supplier Payments",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/account",
    name: "UserAccount",
    component: () =>
      import(/* webpackChunkName: "InventoryChunk" */ "@/Views/Auth/User.vue"),
    meta: {
      requiresAdminAuth: true,
    },
    children: [
      {
        path: "profile",
        name: "AccountProfile",
        component: () =>
          import(
            /* webpackChunkName: "InventoryChunk" */ "@/Views/Auth/Profile.vue"
          ),
        meta: {
          title: "Profile",
          requiresAdminAuth: true,
        },
      },
      {
        path: "security",
        name: "AccountSecurity",
        component: () =>
          import(
            /* webpackChunkName: "InventoryChunk" */ "@/Views/Auth/Security.vue"
          ),
        meta: {
          title: "Change Password",
          requiresAdminAuth: true,
        },
      },
      {
        path: "login-history",
        name: "LoginHistory",
        component: () =>
          import(
            /* webpackChunkName: "InventoryChunk" */ "@/Views/Auth/Logins.vue"
          ),
        meta: {
          title: "Login Activities",
          requiresAdminAuth: true,
        },
      },
    ],
  },
  {
    path: "/finance/bills/:reference?",
    name: "Bills",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Finance/Bills.vue"
      ),
    meta: {
      title: "Bills",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/finance/bills/:action/:reference?",
    name: "CreateBills",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Finance/BillCreator.vue"
      ),
    meta: {
      title: "Bills",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/finance/expenses/:reference?",
    name: "Expenses",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Finance/Expenses.vue"
      ),
    meta: {
      title: "Expenses",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/finance/banking",
    name: "Banking",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Finance/Banking.vue"
      ),
    meta: {
      title: "Banking",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/finance/journals",
    name: "ManualJournals",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Finance/ManualJournals.vue"
      ),
    meta: {
      title: "Manual Journals",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/finance/journals/:action/:uuid?",
    name: "ManualJournalsCreator",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Finance/ManualJournalCreator.vue"
      ),
    meta: {
      title: "Add Manual Journals",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/finance/expenses/:action/:reference?",
    name: "ExpenseCreator",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Finance/ExpenseCreator.vue"
      ),
    meta: {
      title: "Create Expenses",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/stock/returns/:action/:reference?",
    name: "ReturnCreator",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Stock/Return.vue"
      ),
    meta: {
      title: "Return Creator",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/production/stock/returns/:action/:reference?",
    name: "ProductionReturnCreator",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Stock/Return.vue"
      ),
    meta: {
      title: "Return Creator",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/production/stock/ingredients-consumption",
    name: "IngredientsConsumption",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Production/Consumed.vue"
      ),
    meta: {
      title: "Ingredients Consumption",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/branches/quotes",
    name: "Estimates",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Branches/Estimates.vue"
      ),
    meta: {
      title: "Proformas",
      requiresAdminAuth: true,
    },
  },
  {
    path: "/branches/quotes/:action/:reference?",
    name: "EstimateCreator",
    component: () =>
      import(
        /* webpackChunkName: "InventoryChunk" */ "@/Views/Branches/EstimateCreator.vue"
      ),
    meta: {
      title: "Add New Estimate",
      requiresAdminAuth: true,
    },
  },
];
